$fa-font-path: '~@fortawesome/fontawesome-pro/webfonts';
@import '~@fortawesome/fontawesome-pro/scss/fontawesome';
@import '~@fortawesome/fontawesome-pro/scss/brands';
@import '~@fortawesome/fontawesome-pro/scss/regular';
@import '~@fortawesome/fontawesome-pro/scss/light';
@import '~@fortawesome/fontawesome-pro/scss/solid';
@import "~react-image-gallery/styles/css/image-gallery";
@import '~bootstrap-css-only/css/bootstrap.min';
@import '~mdbreact/dist/css/mdb';
@import '~react-toastify/dist/ReactToastify.min';
@import '~react-datetime/css/react-datetime';

table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc_disabled:after {
  font-family: 'Font Awesome 5 Pro' !important;
}

.dropdown-content.fadeIn {
  display: block;
}

.pickadate--input-root {
  z-index: 3 !important;
}

.enabled-true {
  border-left: 8px solid green;
}

.enabled-false {
  border-left: 8px solid red;
}

@keyframes highlight-yellow {
  0% {
    background: #c5cae9;
  }

  100% {
    background: none;
  }
}

.highlight-yellow {
  animation: highlight-yellow 6s;
}

hr {
  color: gray;
  border-style: inset;
  border-width: 1px;
  margin-block-start: 0.5em;
  margin-inline-end: auto;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-left: 0;
  margin-right: 0;
}

.view-cascade {
  .image-gallery-slide div {
    position: relative;
    height: 170px;
    overflow: hidden;

    img {
      position: absolute;
      width: 100%;
      height: auto;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
}

.image-gallery-slide .image-gallery-image {
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

.image-gallery-image {
  height: 300px;
}

.fit-image {
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    width: 100%;
    height: auto;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.btn-floating {
  z-index: 5 !important;
}

.range-popup {
  position: relative;
  top: -20px;
  color: white;
  white-space: nowrap;
  background-color: red;
}

.image-gallery-left-nav {
  opacity: 0.5;
  height: 100%;
}

.image-gallery-right-nav {
  opacity: 0.5;
  height: 100%;
}

.image-gallery-content:hover {
  .image-gallery-left-nav {
    opacity: 1;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0) 100%);
    background-repeat: no-repeat;
    height: 100%;
    transition: all 200ms linear;
  }

  .image-gallery-right-nav {
    opacity: 1;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0) 100%);
    background-repeat: no-repeat;
    height: 100%;
  }
}


.image-gallery-svg {
  height: 40px !important;
}

.image-gallery-description {
  background: rgba(255, 0, 0, 0.4) !important;
  color: #fff;
  top: 70px;
  bottom: unset !important;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  white-space: normal;
}

.set-avatar {
  color: #fff;
  right: 20px;
  top: 10px;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  white-space: normal;
  z-index: 4;
  cursor: pointer;
  opacity: .6;

  &.false {
    background: rgb(81, 201, 81) !important;
  }

  &.true {
    background: rgb(185, 160, 48) !important;
  }

  &:hover {
    opacity: 1;
  }
}

.image-gallery.fullscreen-modal {
  z-index: 1040;

  img {
    max-height: calc(100vh - 100px);
    max-width: 100vw;
    object-fit: scale-down;
    font-family: 'object-fit: scale-down';
    height: auto;
  }
}

.tabs-white {
  li a {
    color: #212529 !important;
    opacity: .5;

    &:hover {
      opacity: .75;
    }
  }

  li a.active {
    opacity: 1;
  }
}

.bid {
  .md-form {
    margin: 0;
  }

  input {
    margin: 0 !important;
  }
}

.bid-status {
  height: 9px;
  width: 9px;
  border-radius: 100%;
  display: none;

  &.highest {
    &.my-true {
      background-color: #00C851;
    }

    &.my-false {
      background-color: #ffbb33;
    }

    display: inline-block;
  }

  &.outbid {
    background-color: #9e9e9e;
    display: inline-block;
  }
}

.Select-menu-outer {
  z-index: 1000;
  position: relative;
}

.active-menu-link {
  border-left: 4px solid #272a7f;
}

[class*="steps-form"] [class*="steps-step"] {
  &.current {
    button {
      border-color: #4285f4 !important;
    }

    button i {
      color: #4285f4 !important;
    }
  }

  &.passed {
    button {
      border-color: #00C851 !important;
    }

    button i {
      color: #00C851 !important;
    }
  }

  &.upcoming {
    button {
      border-color: rgb(192, 191, 191) !important;
    }

    button i {
      color: rgb(192, 191, 191) !important;
    }
  }
}


.scrollbar {
  overflow-y: scroll;
}

.scrollbar-primary::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #4285F4;
}

.scrollbar-danger::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-danger::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-danger::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #ff3547;
}

.scrollbar-warning::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-warning::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-warning::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #FF8800;
}

.scrollbar-success::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-success::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-success::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #00C851;
}

.scrollbar-info::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-info::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-info::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #33b5e5;
}

.scrollbar-default::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-default::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-default::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #2BBBAD;
}

.scrollbar-secondary::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-secondary::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-secondary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #aa66cc;
}

.scrollbar-pink::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-pink::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-pink::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #ec407a;
}

.scrollbar-indigo::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-indigo::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-indigo::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #3f51b5;
}

.scrollbar-black::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-black::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-black::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #000;
}

.scrollbar-lady-lips::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-lady-lips::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-lady-lips::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#ff9a9e), color-stop(99%, #fecfef),
      to(#fecfef));
  background-image: -webkit-linear-gradient(bottom, #ff9a9e 0%, #fecfef 99%, #fecfef 100%);
  background-image: linear-gradient(to top, #ff9a9e 0%, #fecfef 99%, #fecfef 100%);
}

.scrollbar-near-moon::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #F5F5F5;
  border-radius: 10px;
}

.scrollbar-near-moon::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.scrollbar-near-moon::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-image: -webkit-gradient(linear, left bottom, left top, from(#5ee7df), to(#b490ca));
  background-image: -webkit-linear-gradient(bottom, #5ee7df 0%, #b490ca 100%);
  background-image: linear-gradient(to top, #5ee7df 0%, #b490ca 100%);
}

.bordered-pink::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border: 1px solid #ec407a;
}

.bordered-pink::-webkit-scrollbar-thumb {
  -webkit-box-shadow: none;
}

.bordered-indigo::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border: 1px solid #3f51b5;
}

.bordered-indigo::-webkit-scrollbar-thumb {
  -webkit-box-shadow: none;
}

.bordered-black::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  border: 1px solid #000;
}

.bordered-black::-webkit-scrollbar-thumb {
  -webkit-box-shadow: none;
}

.square::-webkit-scrollbar-track {
  border-radius: 0 !important;
}

.square::-webkit-scrollbar-thumb {
  border-radius: 0 !important;
}

.thin::-webkit-scrollbar {
  width: 6px;
}

.ap-timeline {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 16px;
    border-radius: 8px;
    background: #c5cae9;
  }

  .ap-timeline-year-block {
    margin-left: 35px;
    border-bottom: 2px solid #3f51b5;

    .ap-timeline-year {
      color: #3f51b5;
      font-size: .8em;
      font-weight: bold;
    }
  }

  .ap-timeline-request {
    position: relative;
    border: 1px solid #e8eaf6;

    .ap-timeline-header {
      font-size: .9em;

      .ap-timeline-date {
        font-weight: bold;
        width: 90px;
      }
    }

    &::before {
      content: "";
      position: absolute;
      left: -34px;
      top: 6px;
      height: 12px;
      width: 12px;
      border-radius: 100%;
      background-color: #5c6bc0;
    }
  }
}

.classic-tabs .nav li {
  a {
    color: rgba(255, 255, 255, 0.4) !important;

    &.active {
      color: rgba(255, 255, 255, 1) !important;
    }
  }
}